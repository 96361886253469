import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'spinnerContainer',
    'form',
    'indexContainer',
    'averageRate',
    'checkbox',
    'availability',
    'availabilityOption',
    'continueButton'
  ]
  
  connect() {
    addEventListener('turbo:frame-render', () => {
      this.ensureCheckboxesSelection()
      this.ensureCorrectOptionSelection()
    })
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => {
      this.poll()
    }, 1500)

    $(this.indexContainerTarget).addClass('hidden')
    $(this.spinnerContainerTarget).removeClass('hidden')

    this.timeout = setTimeout(() => {
      const message = `
        <div id='search-taking-too-long' class='text-center'>
          <p class="text-gray-800 text-sm">We're working on finding your available properties—this may take a few minutes.</p>
          <p class="text-gray-800 text-sm">Thank you for your patience!</p>
        </div>
      `
      this.spinnerContainerTarget.insertAdjacentHTML('beforeend', message)
    }, 30000)
  }

  poll() {
    const url = this.data.get('status-url')
    fetch(`${url}&id=${this.pmsPropertyAvailabilityIndexQueryId}`)
      .then(response => response.json())
      .then(json => {
        if (json.finished) {
          clearInterval(this.interval)
          clearTimeout(this.timeout)
          const searchTakingTooLong = document.getElementById('search-taking-too-long')
          if (searchTakingTooLong) {
            searchTakingTooLong.remove()
          }
          this.loadIndex()
        }
      })
  }

  handleFormSubmit(e) {
    if (this.interval) {
      clearInterval(this.interval)
    }

    const searchTakingTooLong = document.getElementById('search-taking-too-long')
    if (searchTakingTooLong) {
      searchTakingTooLong.remove()
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    const pmsPropertyAvailabilityIndexId = e.detail[0].querySelector('[data-pms-availability-indices-id-value]')
    this.pmsPropertyAvailabilityIndexQueryId = (pmsPropertyAvailabilityIndexId?.dataset?.pmsAvailabilityIndicesIdValue) || null

    if (this.pmsPropertyAvailabilityIndexQueryId) {
      this.startPolling()
    }

    const updatedForm = e.detail[0].querySelector('[data-target="pms-availability-indices.form"]')
    this.formTarget.innerHTML = updatedForm.innerHTML
    this.selectedProperties = []
  }

  loadIndex() {
    const url = this.data.get('index-url')

    fetch(`${url}&id=${this.pmsPropertyAvailabilityIndexQueryId}`)
      .then(response => response.text())
      .then(text => {
        $(this.spinnerContainerTarget).addClass('hidden')
        $(this.indexContainerTarget).removeClass('hidden')
        $(this.indexContainerTarget).html(text)
      })
  }

  onAvailabilityChange(e) {
    const availabilityId = e.currentTarget.value
    const pmsPropertyId = e.currentTarget.dataset.pmsPropertyId

    const option = this.availabilityOptionTargets.find(option => option.value === availabilityId)

    const checkbox = this.checkboxTargets.find(checkbox => checkbox.dataset.pmsPropertyId === pmsPropertyId)
    checkbox.dataset.pmsPropertyAvailabilityId = availabilityId
    
    if (checkbox.checked) {
      this.updateSelectedProperties(availabilityId, pmsPropertyId)
    }

    const rate = this.averageRateTargets.find(rate => rate.dataset.pmsPropertyId === pmsPropertyId)
    rate.innerHTML = `${option.dataset.humanizedRateWithCurrency}`
  }

  toggleProperty(e) {
    const { pmsPropertyAvailabilityId: availabilityId, pmsPropertyId } = e.currentTarget.dataset

    if (e.currentTarget.checked) {
      this.pushPropertyToSelectedProperties({ availabilityId, pmsPropertyId })
    } else {
      this.removeAvailabilityFromSelectedProperties(pmsPropertyId)
    }

    this.disableAvailabilityOptions()
  }

  updateSelectedProperties(availabilityId, pmsPropertyId) {
    this.removeAvailabilityFromSelectedProperties(pmsPropertyId)
    this.pushPropertyToSelectedProperties({ availabilityId, pmsPropertyId })
  }

  pushPropertyToSelectedProperties(propertyDetails) {
    if (!this.selectedProperties) { this.selectedProperties = [] }
    this.selectedProperties.push(propertyDetails)
    this.updateContinueButtonText()
  }

  removeAvailabilityFromSelectedProperties(pmsPropertyId) {
    this.selectedProperties = this.selectedProperties.filter(property => property.pmsPropertyId !== pmsPropertyId)
    this.updateContinueButtonText()
  }

  disableAvailabilityOptions() {
    const selectedPropertiesLengthExceed = this.selectedProperties.length === 4
    this.checkboxTargets.forEach(checkbox => {
      if (!checkbox.checked) {
        checkbox.disabled = selectedPropertiesLengthExceed ? 'disabled' : ''
      }
    })
  }

  ensureCheckboxesSelection() {
    this.selectedProperties.forEach(property => {
      const checkbox = this.checkboxTargets.find(checkbox => checkbox.dataset.pmsPropertyId === property.pmsPropertyId)
      if (checkbox) {
        checkbox.checked = true
      }
    })

    this.disableAvailabilityOptions()
  }

  ensureCorrectOptionSelection() {
    this.selectedProperties.forEach(property => {
      const option = this.availabilityOptionTargets.find(option => option.value === property.availabilityId)
      if (option) {
        option.selected = true
      }
    })
  }

  updateContinueButtonText() {
    const propertyCount = this.selectedProperties.length

    if (propertyCount === 0) {
      this.continueButtonTarget.innerHTML = 'Continue'
    } else {
      const propertyText = propertyCount === 1 ? 'property' : 'properties'

      this.continueButtonTarget.innerHTML = `Continue with ${propertyCount} ${propertyText}`
    }
  }

  onContinueClick(e) {
    e.preventDefault()

    if (this.selectedProperties && this.selectedProperties.length > 0) {
      this.continueButtonTarget.setAttribute('disabled', 'disabled')
      this.continueButtonTarget.innerHTML = '<div class="spinner spinner--xsmall mx-16"></div>'

      $.post(e.currentTarget.dataset.url, {
        pms_property_availability_index_query: {
          selected_properties_attrs: [
            ...this.selectedProperties.map(property => ({
              pms_property_id: property.pmsPropertyId,
              pms_property_availability_id: property.availabilityId
            }))
          ]
        }
      }).done((response) => {
        document.dispatchEvent(new CustomEvent('pms_properties_availabilities_selected', { detail: response }))
        document.dispatchEvent(new CustomEvent('takeover_hide'))

        this.continueButtonTarget.removeAttribute('disabled')
        this.updateContinueButtonText()
      })
    } else {
      document.dispatchEvent(new CustomEvent('takeover_hide'))
    }
  }
}
