import { Controller } from 'stimulus'
import { xor } from 'lodash'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['reasonsInput', 'form']

  onReasonChange(e) {
    this.selectedReasons = xor(this.selectedReasons, [e.target.value])
  }

  onSubmit(e) {
    e.preventDefault()

    if (this.selectedReasons) {
      this.reasonsInputTarget.value = this.selectedReasons.join(',')
    }

    Rails.fire(this.formTarget, 'submit')
  }

  onContactClick() {
    document.dispatchEvent(new CustomEvent('close_modal'))
  }
}
