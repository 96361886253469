import { Controller } from 'stimulus'
import { numberWithCommas } from '../../utilities'
import { EMAIL_MARKETING_ONBOARDING_SUBSCRIBER_COUNT_INFO } from '../../utilities/shared/subscription_pricing_values'

export default class extends Controller {
  static targets = [
    'subscriberCount',
    'price',
    'unit',
    'subscriberCountIndexInput',
    'currency',
    'discountedPrice',
    'discountedCurrency',
    'discountedUnit'
  ]

  static THUMB_WIDTH = 26

  static SUBSCRIBER_COUNT_INFO = EMAIL_MARKETING_ONBOARDING_SUBSCRIBER_COUNT_INFO.map((info, index) => {
    const isLastInfo = index === EMAIL_MARKETING_ONBOARDING_SUBSCRIBER_COUNT_INFO.length - 1

    return {
      ...info.plusPlan,
      currency: info.currency,
      unit: info.unit,
      contactText: isLastInfo
        ? `${numberWithCommas(info.subscribersRange.lowerLimit)}+`
        : `${numberWithCommas(info.subscribersRange.lowerLimit)} &ndash; ${numberWithCommas(info.subscribersRange.upperLimit)}`,
    }
  })

  connect() {
    this.updateSubscriberCount()
  }

  updateSubscriberCount() {
    const value = this.subscriberCountIndexInputTarget.value
    const min = this.subscriberCountIndexInputTarget.min
    const max = this.subscriberCountIndexInputTarget.max
    const info = this.constructor.SUBSCRIBER_COUNT_INFO[value]
    const percent = (value - min) * 100 / (max - min)
    const thumbOffset = this.constructor.THUMB_WIDTH / 100 * percent
    const backgroundOffset = this.constructor.THUMB_WIDTH / 2 - thumbOffset

    this.subscriberCountIndexInputTarget.style.backgroundSize = `calc(${percent}% + ${backgroundOffset}px) 100%`
    $(this.subscriberCountTargets).html(info.contactText)
    $(this.priceTargets).html(info.formattedPrice)
    $(this.unitTargets).text(info.unit)
    $(this.currencyTargets).text(info.currency)
    $(this.discountedPriceTargets).html(info.discountedPrice)

    if (info.discountedPrice === 'FREE') {
      $(this.discountedCurrencyTargets).html('')
      $(this.discountedUnitTargets).html('')
    } else {
      $(this.discountedCurrencyTargets).html(info.currency)
      $(this.discountedUnitTargets).html(info.unit)
    }
  }
}
